import React, { useContext, useEffect, useState } from "react";
import { graphql } from "gatsby";
import tw, { styled } from "twin.macro";
import { useDispatch, useSelector } from "react-redux";
// import LanguageNavigation from "../language-navigation";
import { showFeedback } from "../../store/actions/feedback";
import { ThemeContext } from "../themes/theme-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle, faStopCircle } from "@fortawesome/free-solid-svg-icons";

const Container = tw.div`bg-white fixed bottom-0 w-full z-20 lg:bg-transparent min-w-screen`;

const InnerContainer = tw.div`max-w-screen-xl mx-auto`;

const StyledBottomNav = tw.nav`justify-center items-center flex flex-row py-0 lg:bg-none lg:px-10 lg:py-5`;

// const StyledLanguageNav = tw.div`hidden lg:block lg:w-1/2`;

const StyledButtons = styled.div(({ textColor }) => [
	tw`flex flex-row text-sm w-full lg:w-full lg:justify-end lg:items-end lg:space-x-2.5`,
	textColor,
]);

const StyledButton = styled.button(({ borderColor }) => [
	tw`bg-white flex flex-row w-1/3 text-center justify-center items-center text-sm leading-sans-sm p-1 md:py-2.5 md:text-base md:leading-sans-base lg:text-lg lg:leading-sans-lg lg:shadow-bottom-nav lg:w-max lg:rounded-full lg:py-4 lg:px-5 transition duration-150 ease-linear transform hover:scale-105 border-t not-last-of-type:border-r lg:border`,
	borderColor,
]);

const StyledIcon = tw.div`w-4 mr-1 md:w-5 md:mr-2 lg:w-6`;

const StyledLabel = tw.div`leading-tight`;

const Spacer = styled.div(({ borderColor }) => [
	tw`h-6 border-t lg:hidden`,
	borderColor,
]);

const BottomNavigation = ({ data, languageData }) => {
	const audioData = data.nodes[0].audio.file;
	const backgroundButton = data.nodes[0].links[0];
	const audioButton = data.nodes[0].links[1];
	const feedbackButton = data.nodes[0].links[2];

	const feedbackOn = useSelector((state) => state.feedback.show === "general");

	const dispatch = useDispatch();

	const {
		theme,
		audioPlaying,
		loadData,
		toggleAudio,
		bgPlaying,
		toggleBackground,
	} = useContext(ThemeContext);

	useEffect(() => {
		// console.log("this happened after loading?");
		loadData(audioData);
		return () => {};
	});

	const handleBackground = () => {
		toggleBackground(!bgPlaying);
	};

	const handleAudio = () => {
		toggleAudio(!audioPlaying);
	};

	const handleFeedback = () => {
		dispatch(showFeedback("general"));
	};

	const [isBottom, setIsBottom] = useState(false);

	useEffect(() => {
		const handleScroll = (e) => {
			const position = window.innerHeight + window.scrollY;
			const clientHeight = document.body.offsetHeight;
			// console.log(position, clientHeight);
			if (position <= clientHeight && position >= clientHeight) {
				if (isBottom === false) {
					setIsBottom(true);
				}
			} else {
				if (isBottom === true) {
					setIsBottom(false);
				}
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	});

	return (
		<Container>
			<InnerContainer>
				<StyledBottomNav>
					<StyledButtons textColor={theme.textColor}>
						<StyledButton
							key={backgroundButton.title}
							onClick={handleBackground}
							borderColor={theme.buttonBorder}
						>
							<StyledIcon>
								{bgPlaying ? (
									<FontAwesomeIcon icon={faStopCircle} aria-hidden="true" />
								) : (
									<FontAwesomeIcon icon={faPlayCircle} aria-hidden="true" />
								)}
							</StyledIcon>
							<StyledLabel>
								{bgPlaying
									? backgroundButton.activeLabel
									: backgroundButton.label}
							</StyledLabel>
						</StyledButton>
						<StyledButton
							key={audioButton.title}
							onClick={handleAudio}
							borderColor={theme.buttonBorder}
						>
							<StyledIcon>
								{audioPlaying ? (
									<img
										tw="w-full h-full"
										src={theme.iconAudioOff}
										alt={audioButton.label}
										aria-hidden="true"
									/>
								) : (
									<img
										tw="w-full h-full"
										src={theme.iconAudioOn}
										alt={audioButton.label}
										aria-hidden="true"
									/>
								)}
							</StyledIcon>
							<StyledLabel>
								{audioPlaying ? audioButton.activeLabel : audioButton.label}
							</StyledLabel>
						</StyledButton>
						<StyledButton
							key={feedbackButton.title}
							onClick={handleFeedback}
							borderColor={theme.buttonBorder}
						>
							<StyledIcon>
								<img
									tw="w-full h-full"
									src={theme.iconFeedback}
									alt={feedbackButton.label}
									aria-hidden="true"
								/>
							</StyledIcon>
							<StyledLabel>
								{feedbackOn ? feedbackButton.label : feedbackButton.activeLabel}
							</StyledLabel>
						</StyledButton>
					</StyledButtons>
				</StyledBottomNav>
				{isBottom && <Spacer borderColor={theme.buttonBorder} />}
			</InnerContainer>
		</Container>
	);
};

export const bottomNavigationFragment = graphql`
	fragment bottomNavigation on ContentfulBottomNavigation {
		links {
			title
			label
			activeLabel
		}
		audio {
			file {
				contentType
				fileName
				url
			}
		}
	}
`;

export default BottomNavigation;
